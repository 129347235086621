import React, {useEffect, useState} from 'react';
import bannerImg from '../background.jpeg';
import { Grid, Box, Typography, List, ListItem, ListSubheader} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { ShoppingCart } from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import CheckoutFooter from './CheckoutFooter';
import ScrollHeader from './ScrollHeader';
import CountDown from './CountDown';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content:{
    [theme.breakpoints.down('xs')]: {
      //display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mainContent: {
    backgroundColor: "white",
    //marginTop:"50vh",
    borderRadius:"30px",
    [theme.breakpoints.down('xs')]: {
      //display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  orderListContainer: {
    margin: "20px",
  },
  orderHeader:{
    paddingTop: "25px",
  },
  listSubheader: {
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "2px",
    margin: "20px 0",
  },
  listItem: {
    borderBottom: "1px solid #ccc",
    padding: "20px 0",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
  itemTitle: {
    fontWeight: "bold",
    marginBottom: "5px",
  },
  itemDescription: {
    color: "#666",
  },
  itemPrice: {
    fontSize: "1.5rem",
    color:"#009de0",
    fontWeight: "bold",
  },
  timer: {
    fontFamily: '"Digital", sans-serif',
    fontSize: '6rem',
    color: '#009de0',
    textAlign: 'center',
    margin: '0'
  },
  timerText: {
    fontFamily: '"WoltHeading-Omnes", system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif',
    fontSize: '1.5rem',
    fontWeight: "bold",
    color: 'white',
    textAlign: 'left',
    margin: '0'
  },
  title: {
    fontSize: "2rem",
    fontFamily: "'M PLUS Rounded 1c', sans-serif",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  totalPrice: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
}));



function PhoneComponent(props) {

  const {isPlannedForLater, selectedTime, selectedDay, bannerImg, orderDataFormatted, orderData, state, timeStamp, totalPrice, delivery, deliveryTime, title, motto, discountRate, deliveryPrice, freeDeliveryPrice} = props;

  const classes = useStyles();

  const restaurantName = "Alforno Pizza";
  const orders = orderDataFormatted;


  useEffect(()=>{

    console.log("isPlannedForLater: ", isPlannedForLater);

  },[delivery]);


  return (
    <div className={classes.content}>
      <ScrollHeader timeStamp={timeStamp} deliveryTime={deliveryTime} state={state} />
      <div id="bannerImg">

        <div id="bannerImg">
          <img src={`https://storage.googleapis.com/tas-images/Banners/${bannerImg}`} style={{ "position": "absolute", "width": "100vw", "height": "60vh", "object-fit": "cover", "zIndex":"-2" }} alt="venueBackGround" />
          <div style={{ "width": "100vw", "height": "60vh", "position": "absolute", "backgroundColor": "#0000003d", "zIndex":"-1" }} >
            {/*<Container maxWidth="lg" style={{ "display": "flex", "justifyContent": "space-around", "alignItems": "center", "height": "100%" }}>
              <div style={{ "display": "flex", "alignItems": "center", "color": "white", "textShadow": "1px 1px #000" }}>
                <FastfoodIcon style={{ "marginRight": "2.0rem" }} />
                <Typography className="font-link" style={{ fontSize: "2rem", fontWeight: "bold" }} >Free delivery for orders over 200 kr</Typography>
              </div>
              <div style={{ "display": "flex", "alignItems": "center", "color": "white", "textShadow": "1px 1px #000" }}>
                <CardGiftcardIcon style={{ "marginRight": "2.0rem"}} />
                <Typography className="font-link" style={{ fontSize: "2rem", fontWeight: "bold" }} >10% Discount for online purchases</Typography>
              </div>
            </Container>*/}
          </div>
        </div>


      </div>

      <div style={{"position":"absolute", "width":"100vw", "overflow":"clip"}}>
        <Container maxWidth="lg">
          <Box style={{"bottom":"55vh","zIndex":"-1"}} >
          <Typography variant="h6" className={classes.timerText}>
            {delivery === true ? "Maden bliver leveret om" : "Maden kan hentes om"}
          </Typography>
            <Typography variant="h6" className={classes.timerText} >
              {timeStamp && <CountDown orderTimeStamp={timeStamp} deliveryTime={deliveryTime} state={state} isPlanningForLater={isPlannedForLater} selectedDay={selectedDay} selectedTime={selectedTime} />}
            </Typography>
            {/*<Typography variant="h6" className={classes.timerText} >00:00</Typography>*/}
            <h1 className="res-name" style={{"color":"white", "fontWeight":"700", "fontSize":"3rem","margin":"0px"}} >{title}</h1>
            <h3 className="font-link" style={{"color":"white","fontFamily": 'WoltHeading-Omnes, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif', "fontWeight":"700", "fontSize":"2rem", "margin":"0px"}} >{motto}</h3>
            {/*<h5 className="font-link" style={{"color":"#202125","backgroundColor":"white","fontWeight":"700","width":"fit-content", "padding":"10px", "borderRadius":"4px"}}> MIN. ORDER {minPrice} kr </h5>*/}
          </Box>
        </Container>

        <div id="mainContent" className={classes.mainContent}>
          <Container maxWidth="lg" style={{marginBottom:"15vh"}}>
            <Box className={classes.orderHeader}>
              <Typography variant="h6" className={classes.title}>
                Orders
                <ShoppingCart className={classes.checkoutIcon} />
              </Typography>
            </Box>
            <Box className={classes.orderListContainer}>
              <List
                subheader={
                  <ListSubheader className="font-link">
                    List of Orders
                  </ListSubheader>
                }
              >
                <Box
                style={{
                  height: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <List style={{ overflow: "overlay" }}>
                  <div>
                    <ul>
                      {orderData.map((order, index) => (
                        <li key={index} style={{ marginBottom: '1rem' }}>
                          <h3>{order.title}</h3>
                          <p>Price: {order.price} kr</p>
                          {order.options && order.options.length > 0 && (
                            <ul>
                              {order.options.map((option, idx) => (
                                <li key={idx}>
                                  <strong>{option.optionKind}:</strong> {option.title}
                                </li>
                              ))}
                            </ul>
                          )}

                          
                          {order.extras && order.extras.length > 0 && (
                            <ul>
                              {order.extras.map((extra, idx) => (
                                <li key={idx}>
                                  <strong>{extra.extraKind}:</strong> {extra.title}
                                  <p>Price: {extra.price}</p>
                                </li>
                              ))}
                            </ul>
                          )}
                          
                        </li>
                      ))}
                      <li>
                        <h4>Håndteringsgebyr: 3 kr</h4>
                      </li>
                      
                      { delivery && (parseFloat(totalPrice) >= parseFloat(freeDeliveryPrice)) ? 
                        <li>
                          <h4>Gratis Levering</h4>
                        </li>
                        :
                        null
                      }
                      { delivery && (parseFloat(totalPrice) < parseFloat(freeDeliveryPrice)) ?
                        <li>
                          <h4>Levering: {deliveryPrice} kr</h4>
                        </li>
                        :
                        null
                      }

                      {(discountRate != "0" && <li>
                        <h4>{discountRate}% Rabat: -{Math.round(((totalPrice / 0.9) * 0.1) * 100) / 100} kr</h4>
                      </li>)}
                    </ul>
                  </div>
                </List>
              </Box>
              </List>
            </Box>
          </Container>
          <CheckoutFooter totalPrice={totalPrice} />
        </div>



      </div>
    </div>
    );
 
}

export default PhoneComponent;
