import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, List, ListItem, Typography, Chip, ListSubheader } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import PhoneComponent from './PhoneComponent';
import CountDown from './CountDown';

import image from '../FoodImg.png';
import image2 from '../FoodImg2.png';
import coverImg from '../background.jpeg';
import '../App.css';

import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBx3wVu82quAesPuBZGR2-rp0b7z7OisP4",
  authDomain: "takeawaysystems-cloud.firebaseapp.com",
  databaseURL: "https://takeawaysystems-cloud-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "takeawaysystems-cloud",
  storageBucket: "takeawaysystems-cloud.appspot.com",
  messagingSenderId: "728810133541",
  appId: "1:728810133541:web:0840b05acc6ebb6f5a07cc",
  measurementId: "G-HREX5MWPVB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#d7bba7",
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    width:"100%",
    /*marginTop:"10%",
    marginBottom:"10%",
    marginLeft:"10%",
    marginRight:"10%"*/
  },
  container: {
    backgroundColor: "#1d1f25",
    borderRadius: "60px",
    width: "80vw",
    minHeight: "80vh",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    boxShadow: "-10px 40px 40px 20px rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  firstColumn: {
    backgroundColor: "#f0e8e4",
    borderRadius: "60px 60px 60px 60px",
    width: "100%",
    [theme.breakpoints.down('xs')]:{
      height:"fit-content",
    },
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  secondColumn: {
    backgroundColor: "#1d2026",
    borderRadius: "0 60px 60px 0",
    width: "100%",
    height: "80vh",
  },
  timer: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.5rem',
    },
    fontFamily: "'M PLUS Rounded 1c', sans-serif",
    color: "white",
  },
  image: {
    height: "100%",
  },
  thirdColumn: {
    backgroundColor: "#1d2026",
    borderRadius: "0 60px 60px 0",
    width: "100%",
    height: "80vh",
  },
  title: {
    fontSize: "2rem",
    fontFamily: "'M PLUS Rounded 1c', sans-serif",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  checkoutIcon: {
    marginLeft: "auto",
  },
  itemTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  itemDescription: {
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
  itemPrice: {
    fontSize: "1.5rem",
    color:"#009de0",
    fontWeight: "bold",
  },
  totalPrice: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },

}));

const MainElements = () => {

  let RESTAURANT_ID = "";

  const classes = useStyles();

  const [orderData, setOrderData] = useState([]);
  const [state, setState] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [discountRate, setDiscountRate] = useState(null);
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [freeDeliveryPrice, setFreeDeliveryPrice] = useState(null);

  const [isPlannedForLater, setIsPlannedForLater] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [bannerImg, setBannerImg] = useState(null);

  const [resTitle, setResTitle] = useState("");
  const [resMotto, setResMotto] = useState("");

  const [orders, setOrders] = useState([]);

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    let orderId = urlParams.get('payment_intent');
    RESTAURANT_ID = urlParams.get('restaurant_id');
    console.log(orderId);

    const restaurantRef = ref(database, "/Restaurants/" + RESTAURANT_ID);
    onValue(restaurantRef, (snapshot) => {
      const data = snapshot.val();
      const title = data.name;
      const motto = data.motto;
      const banner = data.images.banner;
      const discountRate = data.delivery_info.fixed_discount_rate;
      const deliveryPrice = data.delivery_info.delivery_price;
      const freeDeliveryMinOrder = data.delivery_info.free_delivery_min_order;
      console.log("Delivery Price: " + deliveryPrice);
      setResTitle(title);
      setResMotto(motto);
      setDiscountRate(discountRate);
      setDeliveryPrice(deliveryPrice);
      setBannerImg(banner);
      setFreeDeliveryPrice(freeDeliveryMinOrder);
      //console.log(title, motto);
    });

    const queryDeliveryTime = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/deliveryTime");
    onValue(queryDeliveryTime, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setDeliveryTime(JSON.stringify(data));
      }
    });

    const queryState = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/state");
    onValue(queryState, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setState(JSON.stringify(data));
      }
    });

    const queryTimeStamp = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/timestamp");
    onValue(queryTimeStamp, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setTimeStamp(JSON.stringify(data));
        console.log(JSON.stringify(data));
      }
    });



    const queryIsPlannedForLater = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/isPlanningForLater");
    onValue(queryIsPlannedForLater, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        console.log("planned for later retreived as: ", data);
        setIsPlannedForLater(data);
      }
    });



    const querySelectedDay = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/selectedDay");
    onValue(querySelectedDay, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setSelectedDay(data);
      }
    });


    const querySelectedTime = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/selectedTime");
    onValue(querySelectedTime, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setSelectedTime(data);
      }
    });



    const queryTotalPrice = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/totalPrice");
    onValue(queryTotalPrice, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setTotalPrice(data);
      }
    });

    const queryDelivery = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/delivery");
    onValue(queryDelivery, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        if(data === "true"){
          setDelivery(true);
        }else{
          setDelivery(false);
        }
      }
    });

    const queryOrderData = ref(database, "/Orders/" + RESTAURANT_ID + "/" + orderId + "/orderData");
    onValue(queryOrderData, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*'Object.values(data).map((data) => {
          setOrderData((prevData) => {...prevData, data});
        });*/
        setOrderData(data);
      }
    });
    

  },[]);

  /*useEffect(() => {
    let transformedOrders = Object.entries(orderData).map(([key, value]) => {
      const { food, price } = value;
      const { title, options } = food;
      console.log(options);
      return{
        title,
        description: options ? JSON.stringify(options) : "",
        price: `${price} kr`
      };
    });

    setOrders(transformedOrders);
    console.log(orders);
    //console.log(delivery);
  },[orderData]);*/

  useEffect(() => {
    console.log("this is timestamp: " + timeStamp);
  },[timeStamp]);


  return <>
    <Box className={classes.root}>
      <Grid container className={classes.container}>



        <Grid item xs={12} md={4} alignItems="center" justifyContent="center" >
          <Box className={classes.firstColumn}>
            <Box style={{ width: "80%", height: "90%" }}>
              <Typography variant="h6" className={classes.title}>
                Orders
                <ShoppingCart className={classes.checkoutIcon} />
              </Typography>

              <Box
                style={{
                  height: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <List style={{ overflow: "scroll", height: "55vh" }}>
                  <div>
                    <ul>
                      {orderData.map((order, index) => (
                        <li key={index} style={{ marginBottom: '1rem' }}>
                          <h3>{order.title}</h3>
                          <p>Price: {order.price} kr</p>
                          {order.options && order.options.length > 0 && (
                            <ul>
                              {order.options.map((option, idx) => (
                                <li key={idx}>
                                  <strong>{option.optionKind}:</strong> {option.title}
                                  <p>Price: {option.price}</p>
                                </li>
                              ))}
                            </ul>
                          )}

                          {order.extras && order.extras.length > 0 && (
                            <ul>
                              {order.extras.map((extra, idx) => (
                                <li key={idx}>
                                  <strong>{extra.extraKind}:</strong> {extra.title}
                                  <p>Price: {extra.price}</p>
                                </li>
                              ))}
                            </ul>
                          )}

                        </li>
                      ))}
                      <li>
                        <h4>Håndteringsgebyr: 3 kr</h4>
                      </li>
                        { delivery && (parseFloat(totalPrice) >= parseFloat(freeDeliveryPrice)) ? 
                          <li>
                            <h4>Gratis Levering</h4>
                          </li>
                          :
                          null
                        }
                        { delivery && (parseFloat(totalPrice) < parseFloat(freeDeliveryPrice)) ?
                          <li>
                            <h4>Levering: {deliveryPrice} kr</h4>
                          </li>
                          :
                          null
                        }
                      {(discountRate != "0" && <li>
                        <h4>{discountRate}% Rabat: -{Math.round(((totalPrice / 0.9) * 0.1) * 100) / 100} kr</h4>
                      </li>)}
                    </ul>
                  </div>
                </List>
              </Box>

              <Typography variant="h6" className={classes.totalPrice}>
                Total:{" "}
                <span style={{ color: "#009de0" }}>
                  {totalPrice} kr
                </span>
              </Typography>
            </Box>
          </Box>
        </Grid>




        <Grid item xs={12} md={4} >
          <Box className={classes.secondColumn}>
            <Box style={{height: '50%', justifyContent:"center", display: "flex", textAlign: "left"}}>
              <Box style={{ paddingTop: "10%" }}>
                <Typography variant="h5" style={{color:"white", fontSize: "2rem"}}>
                   {delivery ? "Maden ankommer om:":"Maden kan hentes om:"} 
                </Typography>

                <Typography variant="h1" className={classes.timer}>
                  {timeStamp && <CountDown orderTimeStamp={timeStamp} deliveryTime={deliveryTime} state={state}  isPlanningForLater={isPlannedForLater} selectedDay={selectedDay} selectedTime={selectedTime} />}
                </Typography>
              </Box>
            </Box>
            <Box style={{height: '50%', alignItems:"center", justifyContent:"center", display: "flex"}} >
              <img src={image} alt="An image" className={classes.image} />
            </Box>
          </Box>
        </Grid>



        <Grid item xs={12} md={4} >
          <Box className={classes.thirdColumn}>
            <Box style={{height: '50%', justifyContent:"center", display: "flex", textAlign: "left"}}>
              <Box style={{ paddingTop: "5%" }}>
                {/*<Typography variant="h5" style={{color:"white", fontSize: "2rem"}}>
                    Time to delivery 
                </Typography>*/}


                <h1 className="res-name" style={{"color":"white", "fontWeight":"700", "fontSize":"5.5rem","margin":"0px"}} >{resTitle}</h1>
                <h3 className="font-link" style={{"color":"white","fontFamily": 'WoltHeading-Omnes, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif', "fontWeight":"700", "fontSize":"2rem", "margin":"0px","marginTop":"20px"}} >{resMotto}</h3>

                {/*<Typography variant="h1" className={classes.timer}>
                    00:00
                </Typography>*/}
              </Box>
            </Box>
            <Box style={{height: '50%', alignItems:"center", justifyContent:"center", display: "flex"}} >
              <img src={image2} alt="An image" className={classes.image} />
            </Box>
          </Box>
        </Grid>


      </Grid>


    </Box>


    <PhoneComponent isPlannedForLater={isPlannedForLater} selectedDay={selectedDay} selectedTime={selectedTime} bannerImg={bannerImg} deliveryPrice={deliveryPrice} discountRate={discountRate} orderDataFormatted={orders} orderData={orderData} state={state} timeStamp={timeStamp} totalPrice={totalPrice} delivery={delivery} deliveryTime={deliveryTime} title={resTitle} motto={resMotto} freeDeliveryPrice={freeDeliveryPrice} /> 

  </>;
};

export default MainElements;
